import React from 'react';
import PropTypes from "prop-types";

const INITIAL_ANGLE = 45;

class GaugeData extends React.Component {

    constructor(props) {
        super(props);

        this.interval = null;
        // this.angle = INITIAL_ANGLE;

        // this.state = {
        //     angle: INITIAL_ANGLE
        // };
        this.angle = INITIAL_ANGLE;
    }

    componentDidUpdate() {
        // if (this.props.refresh === 0) return;
        //
        // this.interval = setInterval(function onTimerInterval() {
        //
        //     // console.log('interval', this.props.label, this.props.value);
        //
        // }.bind(this), Math.ceil(1000 / this.props.refresh));

        const newAngle = this.props.pointerCalculation(
            INITIAL_ANGLE,
            this.props.value,
            this.props.range
        );

        // console.log(newAngle, '!==', this.angle, this.props.active === false, this.interval === null);

        if (this.props.active === false && this.interval === null && newAngle !== this.angle) {

            this.interval = setInterval(function onTimerInterval() {

                // console.log('interval', this.props.label, this.props.value);

                const newAngle = this.props.pointerCalculation(
                    INITIAL_ANGLE,
                    this.props.value,
                    this.props.range
                );

                const step = (newAngle - this.angle) / 10;

                this.angle = this.angle + step;

                this.forceUpdate();

                // this.setState({
                //     angle: this.angle + step
                // });

                // console.log('newAngle', this.angle, newAngle, this.props.value);

            }.bind(this), Math.ceil(1000 / this.props.refresh));

            setTimeout(function onTimerInterval() {

                clearInterval(this.interval);
                this.interval = null;
                // console.log('clearInterval', this.props.value);

            }.bind(this), 2000);


        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log('shouldComponentUpdate', nextProps.value);

        // this.state = {
        //     angle: INITIAL_ANGLE
        // };
        // this.setState({
        //     angle: nextProps.value
        // });

        const newAngle = this.props.pointerCalculation(
            INITIAL_ANGLE,
            this.props.value,
            this.props.range
        );

        const step = (newAngle - this.angle) / 10;

        this.angle = this.angle + step;

        return true;
    }

    render() {

        // const newAngle = this.props.pointerCalculation(
        //     INITIAL_ANGLE,
        //     this.props.value,
        //     this.props.range
        // );
        //
        // const step = (newAngle - this.angle) / 10;
        //
        // this.angle = this.angle + step;

        // if (this.state.angle !== newAngle && this.props.active === false && this.interval === null) {
        //     this.interval = setInterval(function onTimerInterval() {
        //
        //         console.log('interval', this.props.label, this.props.value);
        //
        //         const newAngle = this.props.pointerCalculation(
        //             INITIAL_ANGLE,
        //             this.props.value,
        //             this.props.range
        //         );
        //
        //         const step = (newAngle - this.angle) / 10;
        //
        //         // this.angle = this.angle + step;
        //
        //         this.setState({
        //             angle: this.state.angle + step
        //         });
        //     }.bind(this), Math.ceil(1000 / this.props.refresh));
        //
        //     setTimeout(function onTimerInterval() {
        //
        //         clearInterval(this.interval);
        //         this.interval = null;
        //         console.log('clearInterval');
        //
        //     }.bind(this), 1000);
        //
        // } else {
        //
        //
        //
        // }

        return (
            <>
                <defs>
                    <linearGradient id="gauge-data-b" x1="-77.2" x2="-13.6" y1="-55.2" y2="-7.5" gradientUnits="userSpaceOnUse" spreadMethod="pad">
                        <stop offset="19.6%" stopColor="#767d84"/>
                        <stop offset="41.6%" stopColor="#fafafa"/>
                        <stop offset="55.3%" stopColor="#dcdddf"/>
                    </linearGradient>
                    <linearGradient id="gauge-data-c" x1="89.2" x2="-9.4" y1="-47.8" y2="102.1" gradientUnits="userSpaceOnUse" spreadMethod="pad">
                        <stop offset="0%" stopColor="#96949a"/>
                        <stop offset="23.9%" stopColor="#fefefe"/>
                        <stop offset="78.4%" stopColor="#fefefe"/>
                        <stop offset="100%" stopColor="#9d9ca1"/>
                    </linearGradient>
                    <linearGradient id="gauge-data-g" x1="12.9" x2="-66.7" y1="-3.3" y2="-83" gradientUnits="userSpaceOnUse" spreadMethod="pad">
                        <stop offset="0%" stopColor="#3e2626"/>
                        <stop offset="53.3%" stopColor="#4f3a38"/>
                        <stop offset="100%" stopColor="#8c827c"/>
                    </linearGradient>
                    <linearGradient id="gauge-data-h" x1="8.3" x2="8.3" y1="-.4" y2="88.3" gradientUnits="userSpaceOnUse" spreadMethod="pad">
                        <stop offset="72.2%" stopColor="#660009" stopOpacity="0"/>
                        <stop offset="79.2%" stopColor="#660009" stopOpacity=".2"/>
                        <stop offset="100%" stopColor="#fff" stopOpacity=".1"/>
                    </linearGradient>
                    <linearGradient id="gauge-data-i" x1="-44.5" x2="-13.8" y1="6.9" y2="44.8" gradientUnits="userSpaceOnUse" spreadMethod="pad">
                        <stop offset="31.4%" stopColor="#600" stopOpacity="0"/>
                        <stop offset="100%" stopColor="#fff" stopOpacity=".1"/>
                    </linearGradient>
                    <linearGradient id="gauge-data-j" x1="8.3" x2="8.3" y1=".4" y2="-88.3" gradientUnits="userSpaceOnUse" spreadMethod="pad">
                        <stop offset="31.4%" stopColor="#600" stopOpacity="0"/>
                        <stop offset="100%" stopColor="#fff" stopOpacity=".4"/>
                    </linearGradient>
                    <radialGradient id="gauge-data-a" cx="0" cy="0" r="107.2" fx="0" fy="0" gradientTransform="matrix(1 0 0 1.00012 -1 -.2)" gradientUnits="userSpaceOnUse" spreadMethod="pad">
                        <stop offset="89.4%" stopColor="#887b76"/>
                        <stop offset="91.8%" stopColor="#0c0201"/>
                        <stop offset="92.9%" stopColor="#0c0201"/>
                        <stop offset="95.7%" stopColor="#958884"/>
                    </radialGradient>
                    <radialGradient id="gauge-data-d" cx="0" cy="0" r="24.2" fx="-3.5" fy="-21.7" gradientTransform="matrix(.15845 .98737 -3.25578 .52181 -22.6 47.3)" gradientUnits="userSpaceOnUse" spreadMethod="pad">
                        <stop offset="0%" stopColor="#fff"/>
                        <stop offset="100%"/>
                    </radialGradient>
                    <radialGradient id="gauge-data-e" cx="0" cy="0" r="5.9" fx=".1" fy="0" gradientTransform="matrix(1 0 -.00213 3.85957 6.6 .1)" gradientUnits="userSpaceOnUse" spreadMethod="pad">
                        <stop offset="36.9%" stopColor="#6d3d00"/>
                        <stop offset="62%" stopColor="#ece7e2"/>
                    </radialGradient>
                    <radialGradient id="gauge-data-f" cx="0" cy="0" r="16.3" fx="6.5" fy="-6.4" gradientTransform="matrix(.70787 -.70634 .56814 .56968 -5.9 -3.8)" gradientUnits="userSpaceOnUse" spreadMethod="pad">
                        <stop offset="46.3%" stopColor="#fff"/>
                        <stop offset="76.1%" stopColor="#6d3d00"/>
                    </radialGradient>
                </defs>

                <path fill="#002a3a" d="M73.7-73.6Q63.6-83.8 52-90.5 29-104 .5-104q-42.9 0-73.1 30.4Q-103-43.2-103-.1q0 5 .5 9.8 3.1 36.8 29.9 63.8Q-42.4 104 .5 104q42.9 0 73.2-30.5Q103.9 43 103.9 0q0-43.1-30.2-73.6m-73-27.5q42-.2 71.5 29.4Q101.7-42 101.5-.3q0 41.8-29.7 71.5Q42.1 100.9.2 101q-41.8.2-71.3-29.4-29.5-29.5-29.5-71.3.3-41.9 30-71.6Q-41-101 .6-101.1z"/>
                <path fill="url(#gauge-data-a)" d="M72.2-71.7Q42.6-101.2.7-101q-41.7.1-71.4 29.8-29.6 29.7-29.9 71.5 0 41.8 29.5 71.4Q-41.5 101.2.3 101q41.9-.1 71.6-29.8 29.7-29.7 29.7-71.5.3-41.9-29.3-71.4m7.5 15.5Q65.4-73 47.7-82.5q-7.2-3.9-14.7-6.4Q51.7-82 66.4-66.5 80.9-51 87.5-32.8q3.5 8.2 5.2 17 3.3 16.8-.1 34Q89.4 35 80.5 49.9q-9 14.9-22.2 25.4-13.8 11-31 15.8-13.5 4.3-28.6 4.3-39.5.3-67.1-27.3-8.5-8.5-14.3-18.1 2.5 5.3 5.5 10.6-13.5-19.9-18-40.8-4.3-19.4-.5-38.5 3.5-17.9 13.5-33.5 9.9-15.2 24.5-26.2 14.6-10.9 31.8-16 17.7-5.2 35.7-3.3Q28.9-95.8 46-86q18.6 10.7 33.6 29.8m-5.4-17.9Q64-84 52-90.4q11.6 6.7 21.7 16.9Q103.9-43.2 103.9 0q0 43-30.2 73.5Q43.4 104 .5 104q-42.9 0-73.1-30.5-26.8-27-30-63.8 2.4 37.5 28.8 65l.3.3.3.3.1.1.8.8Q-42 105 0 105q43.5 0 74.3-30.8Q105 43.5 105 0q0-43.4-30.7-74.1z"/>
                <path fill="#eef2f7" d="M-65.3 64.5q-5.6-5.6-10-11.7-9.2-12-14.1-25.1-4.7-12.4-5.3-25.3.2 7.5 1.5 15.2 2.8 16.1 10.5 32.3Q-77 59.5-68.5 68q27.6 27.5 67.1 27.3 15.2 0 28.6-4.3-17.2 5-37.7 2.4-20.4-2.6-39.2-14.7 21.4 11.3 41 12.5l1.1.1Q-41 88.8-65.3 64.5m-24.9-93.3l1.3-3.2q5.4-13.8 15-26.2l1-1.1q-2.7 3.1-5.1 6.5-8 11.2-12.2 24M64.7-65.2l2.6 2.7q6.5 6.4 11.5 13.8 5.3 7.6 8.8 15.9Q81-51.2 66.4-66.5 51.7-82 33-88.8q-9.9-3.5-20.4-4.5-17.5-2-34.9 2.4-8.6 2.2-16.7 5.9 6.8-3 14.2-5 14.7-4 29.7-2.8 9.7.8 19 3.8 22.8 5.9 40.6 23.8z"/>
                <path fill="url(#gauge-data-b)" d="M23.9-89q-9.3-3-19-3.8-15-1.2-29.7 2.8-7.4 2-14.2 5-7.6 3.5-14.5 8.2-11 7.4-19.2 17.1l-.3.4-.8 1.1Q-83.6-45.7-89-32l-1.3 3.3-1.6 5.6-.5 2.4q-2.4 10.3-2.4 21v2.1q.6 12.9 5.3 25.3 4.9 13.2 14 25.1 2.1 2.7 4.4 5.3Q-82 44.2-87.3 28.7q-4.9-14.6-4.6-30 .3-14.6 5.3-28.6 5-13.6 13.8-25.3 8.9-11.5 20.7-20 12-8.6 26-12.8 14.5-4.5 29.8-3.7 16.3.8 32.2 7.4-6-2.9-12.1-4.8z"/>
                <path fill="#fff" d="M47.8-82.4q17.7 9.5 31.9 26.2Q64.7-75.3 46-86 28.9-95.8 9.7-97.8-8.1-99.7-26-94.5q-17.1 5.1-31.7 16-14.6 11-24.5 26.1-10 15.7-13.5 33.6-3.8 19 .5 38.5 4.5 21 18 40.8-3-5.3-5.5-10.6-7.7-16.2-10.5-32.3-1.3-7.7-1.5-15.2V.3q0-10.8 2.4-21l.5-2.4 1.6-5.6q4.3-12.9 12.2-24.1 2.4-3.4 5-6.5l.3-.4q8.3-9.7 19.2-17.1 6.9-4.7 14.5-8.2 8-3.7 16.8-5.9 17.3-4.4 34.8-2.4 10.5 1 20.4 4.5 7.5 2.5 14.8 6.4z"/>
                <path fill="url(#gauge-data-c)" d="M53-74.2q13.3 10.5 22.5 24.5 9.1 14 12.5 30.4Q91.3-3 88.5 14q-2.8 16.4-10.8 31.1Q69.7 60 56.9 70.7 44 81.2 27.7 86.8 13.8 91.4-2 91.4l-5.5-.1h-1.1q-19.6-1.3-41-12.5 18.8 12 39.3 14.6Q10 96 27.1 91q17.3-4.8 31.1-15.8 13.3-10.5 22.2-25.4Q89.4 35 92.6 18.3q3.4-17.3.1-34-1.7-8.9-5.2-17.1-3.5-8.3-8.8-15.9-5-7.4-11.5-13.8-6.5-6.3-14.3-11.7z"/>
                <path d="M67.2-62.5l-2.7-2.7Q46.8-83.1 24-89q6.1 1.8 12 4.7-15.8-6.6-32.1-7.4-15.3-.8-29.9 3.7-13.9 4.2-26 12.8-11.8 8.5-20.6 20-8.8 11.8-13.8 25.4-5 13.9-5.3 28.6-.3 15.3 4.6 29.9 5.3 15.6 16.2 29.4-2.3-2.6-4.3-5.3 4.4 6.1 10 11.8Q-41 88.8-7.5 91.3l5.5.1q15.9 0 29.6-4.6Q44 81.3 56.9 70.8 69.5 59.9 77.8 45q8-14.7 10.7-31.1 2.8-16.9-.5-33.4-3.4-16.4-12.5-30.4Q66.3-63.6 53-74.2q7.8 5.4 14.2 11.7M0-11q4.2 0 7.2 2.6l.5.5.1.2q3.3 3.2 3.3 7.7 0 4.6-3.3 7.8l-.5.5-.1.1Q4 11.1 0 11.1q-4.5 0-7.8-3.3Q-11 4.6-11 0q0-4.5 3.3-7.8T0-11m-31.9 86.2q-4.3 0-4.3-4.3V57.2q0-4.4 4.4-4.4h63.7q4.3 0 4.3 4.4v13.6q0 4.3-4.3 4.3h-63.8z"/>
                <path fill="url(#gauge-data-d)" d="M-36.1 70.8q0 4.3 4.3 4.3h63.7q4.3 0 4.3-4.3V57.2q0-4.4-4.3-4.4h-63.8q-4.3 0-4.3 4.4v13.6z"/>
                <path fill="url(#gauge-data-e)" d="M7.8-7.8v-.1l-.6-.5V8.4l.1-.2.5-.5q3.3-3.2 3.3-7.8 0-4.5-3.3-7.8z"/>
                <path fill="url(#gauge-data-f)" d="M7.2-8.4q-3-2.7-7.2-2.7-4.5 0-7.8 3.3T-11 0q0 4.6 3.3 7.8 3.3 3.3 7.8 3.3 4.1 0 7.2-2.7V-8.4z"/>
                <path fill="url(#gauge-data-g)" d="M74.3-74v-.1-.2l-.3-.3Q43.3-105 0-105q-43.5 0-74.3 30.8Q-105-43.5-105 0q0 43.5 30.8 74.3l.4.4q-26.4-27.5-28.8-65Q-103 5-103 0q0-43 30.4-73.5Q-42.4-104 .4-104q28.7 0 51.6 13.5Q63.9-84 74.3-74z"/>

                <path fill="#f60" d="M73.3 30.4q-2 4.8-4.6 9.4-5.2 8.6-12.8 16.2l3.8 3.6q8-8 13.5-17.3 2.8-4.8 4.9-9.9l-4.8-2m8.3-8.4q2.7-10.4 2.6-22h-5q0 10.9-2.6 20.6-1.3 5-3.3 9.8l4.8 2q2.1-5.1 3.5-10.4z"/>
                <path fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M60.8-60.8l-9.1 9.1M.1-73.2V-86M86 0h-1.8m-5 0L73 .2M56 56l3.6 3.6 1.2 1.2M56 56l-4.3-4.3M79.2 0h5m-145-60.8l9.1 9.1M-86 0l12.8-.1m12.4 60.9l9.1-9.1"/>
                <path fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".1" d="M33-79.7l-3.3 8m50 38.8l-8 3.3m6.4 62l1.5.6m-8-3.2l1.7.6 4.8 2m-111-112.2l3.3 8m-42.1 42l-8-3.2m8 62.7l-8 3.3"/>

                <text x="0" y="27" fontSize="12" dominantBaseline="middle" textAnchor="middle" fill="#fff">{this.props.label}</text>
                <text x="0" y="40" fontSize="9" dominantBaseline="middle" textAnchor="middle" fill="#fff">{this.props.unit}</text>
                <text x="0" y="64" fontSize="16" dominantBaseline="middle" textAnchor="middle" fill="#fff">{(this.props.value).toFixed(1)}</text>

                {this.props.range.map((value, index) => {
                    let rotation = 225 + (index * 45);
                    return <text key={index} transform={`rotate(${rotation.toString()} 0 0) translate(0 -60)`} x="0" y="0" fontSize="12" dominantBaseline="middle" textAnchor="middle" fill="#fff">{value}</text>
                })}

                <g transform={`scale(-1.41667) rotate(${this.angle.toString()})`}>
                    <path fill="#C30" d="M.8-54.6L.5-55l-.3.3-.2.3.1.4L2-14 .9 3q0 .9-.6 1.5l-.3.3-.5.2h1l.9-.5q.5-.6.5-1.5L3-14 1.1-53.9q0-.4-.3-.8z"/>
                    <path fill="#F60" d="M0-54.4v.1l-.1.4L-2-14-.9 3q0 .9.6 1.5l.3.3.3-.3Q1 3.9 1 3L2-14 .1-53.9v-.5z"/>
                    <path fill="#F93" d="M0-54.4l.2-.3.3-.1L0-55l-.5.1-.3.3q-.3.3-.3.8L-3-14l1.1 17q0 .9.5 1.5l.9.5.5-.3-.3-.3Q-1 3.9-1 3L-2-14l1.9-39.9.1-.4v-.1z"/>
                </g>

                <g transform="matrix(1.01999 0 0 1.01999 -8.7 0)">
                    <path fill="url(#gauge-data-h)" d="M81 15.1q0-3.6-6.3-6.6-5.8-2.7-16.5-4.7Q37.7 0 8.2 0-21.1 0-41.6 4q-10.7 1.9-16.4 4.6-6.4 3-6.4 6.6 0 30.1 21.4 51.4Q-22 87.8 8.3 87.8q30.1 0 51.3-21.3Q81 45.3 81 15.1z"/>
                    <path fill="url(#gauge-data-i)" d="M37.2-23.3q-4.4-4.7-15.1-6.4-9.8-1.6-23.7-.5-12.9 1-27.1 4-13.9 3-25.7 7.1-12.1 4.4-19.1 9Q-81-5-81-.6q0 37 26.2 63.1 3.1 3.2 11.1.4 7.5-2.7 18.5-10.3 9.6-6.7 22-17.5 9.7-8.5 24.6-22.6Q34.9-.4 38.7-9.7q3.3-8.4-1.5-13.6z"/>
                    <path fill="url(#gauge-data-j)" d="M74.7-8.6q6.2-2.9 6.2-6.5 0-30.1-21.3-51.4Q38.3-87.8 8.3-87.8q-30 0-51.3 21.3-21.3 21.3-21.3 51.4 0 3.6 6.3 6.5 5.7 2.8 16.4 4.8Q-21.2 0 8.3 0 37.6 0 58.1-4q10.7-1.9 16.5-4.7z"/>
                </g>
            </>
        );
    }
}

GaugeData.propTypes = {
    label: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    range: PropTypes.array.isRequired,
    pointerCalculation: PropTypes.func.isRequired,
    refresh: PropTypes.number
};

GaugeData.defaultProps = {
    refresh: 0
};

export default GaugeData;
