import React from 'react';
import PropTypes from "prop-types";

class Online extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <rect x="0" y="0"
                      fill="#fff"
                      opacity=".8"
                      width={this.props.width}
                      height={this.props.height}
                />
                <text fill="#dd0000"
                      x={this.props.width / 2}
                      y={this.props.height / 2}
                      fontSize="32"
                      dominantBaseline="middle"
                      textAnchor="middle">Chyba pripojenia!
                </text>
                <text fill="currentColor"
                      x={this.props.width / 2}
                      y={this.props.height / 2 + 32}
                      fontSize="16"
                      dominantBaseline="middle"
                      textAnchor="middle">Počkajte chvíľu a skúste to znova…
                </text>
            </>
        );
    }
}

Online.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
};

Online.defaultProps = {
};

export default Online;
