import React from 'react';
import PropTypes from 'prop-types';
import Gauge from './Gauge';
import {getRange, getInterval} from './Gauge/Utils';
import * as GaugeTypes from '../constants/gauge';
import Upload from '../utils/Upload';
import Timer from '../utils/Timer';

const REFRESH_INTERVAL = 20;

class UploadGauges extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            progress: 0,
            speed: 0,
            avgSpeed: 0,
            maxSpeed: 0,
            active: false
        };

        this.upload = new Upload({
            duration: this.props.duration + 2,
            onUpdate: this.onUpdate.bind(this),
            onComplete: this.onComplete.bind(this),
            debug: false
        });

        this.timer = new Timer({
            refresh: REFRESH_INTERVAL, // per second
            duration: this.props.duration,
            onUpdate: this.onTimeUpdate.bind(this),
            onComplete: this.onTimeComplete.bind(this)
        });

        this.range = getRange(this.props.limit, this.props.minRange);
        this.timeRange = getInterval(this.props.duration);
    }

    onUpdate(payload) {
        this.setState({
            ...payload
        });
    }

    onComplete() {
        this.setState({
            active: false,
            speed: 0
        });

        this.props.complete && this.props.complete({
            avgSpeed: this.state.avgSpeed,
            maxSpeed: this.state.maxSpeed
        });
    }

    onTimeUpdate(time) {
        this.setState({
            progress: time > this.props.duration ? this.props.duration : time
        });
    }

    onTimeComplete(time) {
        this.onTimeUpdate(time);
        this.upload.stop();
    }

    start() {
        this.setState({
            progress: 0,
            speed: 0,
            avgSpeed: 0,
            maxSpeed: 0,
            active: true
        });

        this.upload.start();
        this.timer.start();
    }

    stop() {
        this.setState({
            active: false,
            speed: 0
        });

        this.upload.stop();
        this.timer.stop();
    }

    render() {

        return (
            <>
                <defs>
                    <clipPath id="uploadMask">
                        <rect x="0" y="0" width="290" height="100%" />
                    </clipPath>
                </defs>
                <g transform="translate(290 0)"
                   style={{clipPath: 'url(#uploadMask)'}}
                >
                    <g transform="translate(215 155)">
                        <Gauge label={'Time'}
                               unit={'sec'}
                               type={GaugeTypes.TIME}
                               direction={GaugeTypes.CCW}
                               value={this.state.progress}
                               range={this.timeRange}
                        />
                    </g>
                    <g transform="translate(93 105)">
                        <Gauge label={'Upload'}
                               unit={'Mbit/s'}
                               type={GaugeTypes.DATA}
                               value={this.state.active ? this.state.speed : this.state.maxSpeed}
                               range={this.range}
                               active={this.state.active}
                               refresh={REFRESH_INTERVAL}
                        />
                    </g>
                    <g transform="translate(29.52 270)" fill="currentColor" style={{userSelect: 'text'}}>
                        <text x="0" y="0" fontSize="16" opacity=".5">Odosielanie (Upload)</text>
                        <text x="0" y="30" fontSize="12">Aktuálna rýchlosť</text>
                        <text x="180" y="30" fontSize="12" textAnchor="end" fontWeight="bold"
                              fill={this.state.active ? '#ff0102' : 'currentColor'}>{(this.state.speed).toFixed(1)}</text>
                        <text x="180" y="30" fontSize="12">&nbsp;Mbit/s</text>

                        <text x="0" y="50" fontSize="12">Maximálna rýchlosť</text>
                        <text x="180" y="50" fontSize="12" textAnchor="end" fontWeight="bold"
                              fill={this.state.active ? '#ff3301' : 'currentColor'}>{(this.state.maxSpeed).toFixed(1)}</text>
                        <text x="180" y="50" fontSize="12">&nbsp;Mbit/s</text>

                        <text x="0" y="70" fontSize="12">Priemerná rýchlosť</text>
                        <text x="180" y="70" fontSize="12" textAnchor="end" fontWeight="bold"
                              fill={this.state.active ? '#ff6602' : 'currentColor'}>{(this.state.avgSpeed).toFixed(1)}</text>
                        <text x="180" y="70" fontSize="12">&nbsp;Mbit/s</text>

                        <text x="0" y="90" fontSize="12">Priebeh merania</text>
                        <text x="180" y="90" fontSize="12" textAnchor="end" fontWeight="bold"
                              fill={this.state.active ? '#ff9901' : 'currentColor'}>{(this.state.progress / this.props.duration * 100).toFixed(1)}</text>
                        <text x="180" y="90" fontSize="12">&nbsp;%</text>
                    </g>
                </g>
            </>
        );
    }
}

UploadGauges.propTypes = {
    duration: PropTypes.number,
    limit: PropTypes.number,
    minRange: PropTypes.number,
    complete: PropTypes.func
};

UploadGauges.defaultProps = {
    duration: 15,
    minRange: 10,
    complete: null
};

export default UploadGauges;
