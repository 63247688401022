import React from 'react';
import PropTypes from 'prop-types';
import Download from '../utils/Download';
import Upload from '../utils/Upload';

class Probe extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            download: null,
            upload: null,
            debug: false,
        };

        this.download = new Download({
            averageInterval: 1000,
            duration: 0,
            onComplete: this.setDownload.bind(this),
            debug: false
        });

        this.upload = new Upload({
            averageInterval: 500,
            dataSize: 1024 * 256 * 8,
            duration: 0,
            onComplete: this.setUpload.bind(this),
            debug: false
        });
    }

    componentDidMount() {
        this.download.start();
    }

    setDownload(payload) {
        this.state.debug && console.log('setDownload', payload);

        this.setState({
            download: payload.avgSpeed
        });

        this.upload.start();
    }

    setUpload(payload) {
        this.state.debug && console.log('setUpload', payload);

        this.setState({
            upload: payload.avgSpeed
        });

        this.props.complete(this.state);
    }

    render() {
        return (
            <text fill="#dd0000"
                  x={this.props.width / 2}
                  y={this.props.height / 2}
                  fontSize="32"
                  dominantBaseline="middle"
                  textAnchor="middle">Prebieha načítavanie…
            </text>
        );
    }
}

Probe.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    duration: PropTypes.number,
    complete: PropTypes.func.isRequired
};

Probe.defaultProps = {
    duration: 5
};

export default Probe;
