import React from 'react';
import GaugeTime from './Gauge/GaugeTime';
import GaugeData from './Gauge/GaugeData';
import * as GaugeTypes from '../constants/gauge';

export default class Gauge extends React.Component {

    constructor(props) {
        super(props);

        this.lastAngle = null;
    }


    pointerAngleHandler(initialAngle, value, range, direction = GaugeTypes.CW) {
        let lenRange = range.length,
            minRange = range[0],
            maxRange = range[lenRange - 1],
            skip = 0;

        if (value > maxRange) {
            return initialAngle + (range.length - 1) * 45 + 5;
        }

        for (let i = 0, len = lenRange - 1; i < len; i += 1) {
            if (value >= range[i]) {
                skip = i;
                minRange = range[i];
            } else {
                maxRange = range[i];
                break;
            }
        }

        const lastFraction = (value - minRange) / (maxRange - minRange),
            angle = skip * 45 + lastFraction * 45;

        const totalAngle = direction === GaugeTypes.CW ? initialAngle + angle : initialAngle - angle;

        if (this.props.friction === true) {
            if (this.lastAngle === null) {
                this.lastAngle = initialAngle;
            } else {

                const step = (totalAngle - this.lastAngle) / 10;
                this.lastAngle = this.lastAngle + step;

                return this.lastAngle;
            }
        }

        return totalAngle;
    }

    render () {
        return (
            <>
                {this.props.type === GaugeTypes.TIME &&
                <GaugeTime label={this.props.label}
                           unit={this.props.unit}
                           direction={this.props.direction}
                           value={this.props.value}
                           range={this.props.range}
                           pointerCalculation={this.pointerAngleHandler.bind(this)}
                />
                }

                {this.props.type === GaugeTypes.DATA &&
                <GaugeData label={this.props.label}
                           unit={this.props.unit}
                           value={this.props.value}
                           range={this.props.range}
                           pointerCalculation={this.pointerAngleHandler.bind(this)}
                           active={this.props.active}
                           refresh={this.props.refresh}
                />
                }
            </>
        )
    }
}
