// const RANGE_MULTIPLIER = 2;

export function getRange(range, minRange) {
    // const val = Math.round(range),
    //     len = val.toString().length,
    //     div = Math.pow(10, len - 1),
    //     max = Math.round(val / (div * 0.6)) * div;

    const max = (Math.round(range / minRange) + 1) * minRange;

    return [0, max / 200, max / 100, max / 20, max / 10, max / 2, max];
}

export function getInterval(range) {
    const slice = range / 5
    return [0, slice, slice * 2, slice * 3, slice * 4, range];
}
