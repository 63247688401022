import axios from 'axios';

export default class Upload {

    constructor(options) {
        this.options = {
            averageInterval: 750,
            dataSize: 1024 * 1024 * 20,
            duration: 10,
            // apiUrl: 'http://test.icss.sk/dummy.html',
            // apiUrl: 'http://localhost:5000/upload.stream?speed=2',
            apiUrl: 'https://speedmeter.galik.it/assets/upload.php',
            onUpdate: null,
            onComplete: null,
            ...options
        };

        this.timeout = null;
        this.random = new Blob([this.generateRandomString(this.options.dataSize)]);
    }

    inProgress() {
        return this.timeout !== null;
    }

    isProbe() {
        return this.options.duration === 0;
    }

    setSpeed(speed, avgSpeed) {
        this.speed = speed;
        this.avgSpeed = avgSpeed;
        if (this.speed > this.maxSpeed) {
            this.maxSpeed = speed;
        }
    }

    start() {
        this.options.debug && console.log('Upload start');
        this.speed = 0;
        this.avgSpeed = 0;
        this.maxSpeed = 0;
        this.totalSize = 0; // total data loaded in previous fetches
        this.totalTime = 0; // total time loaded in previous fetches

        if (this.inProgress()) {
            this.stop();
        }

        if (this.isProbe() === false) {
            this.timeout = setTimeout(function onUploadTimeout() {
                this.options.debug && console.log('Upload timeout');
                this.stop(false);
            }.bind(this), this.options.duration * 1000);

            // no proble
            this._loop();
        } else {
            this._fetch();
        }

    }

    stop(callback = true) {
        if (this.inProgress() || this.isProbe()) {
            this.options.debug && console.log('Upload stop');
            clearTimeout(this.timeout);
            this.timeout = null;

            if (this.options.onComplete !== null && callback === true) {
                this.options.onComplete({
                    speed: this.speed,
                    avgSpeed: this.avgSpeed,
                    maxSpeed: this.maxSpeed
                });
            }
        } else {
            this.options.debug && console.log('Upload stop null');
        }
    }

    async _loop() {
        this.options.debug && console.log('Upload _loop');

        do {
            await this._fetch();
        } while (this.inProgress());
    }

    async _fetch() {
        this.options.debug && console.log('Upload _fetch');

        const timeBuffer = [],
              dataBuffer = [],
              avgStartTime = Date.now();

        // total bytes loaded in current _fetch
        let totalLoaded = 0;

        // at the beginning there is no data
        timeBuffer.push(avgStartTime);
        dataBuffer.push(0);
        // dataBuffer.push(0);

        const source = axios.CancelToken.source();

        await axios.post(this.options.apiUrl, this.random, {
            cancelToken: source.token,
            onUploadProgress: function (e) {
                if (this.inProgress() === false && this.isProbe() === false) {
                    source.cancel('progress');
                    return;
                }

                this.options.debug && console.log('Upload progress start', e.loaded, e.timeStamp);

                const now = Date.now();

                timeBuffer.push(now);
                dataBuffer.push(e.loaded);

                // total bytes loaded in current _fetch
                // used for calculation of avgSpeed
                totalLoaded = e.loaded;

                if (timeBuffer[timeBuffer.length - 1] - timeBuffer[0] > this.options.averageInterval) {
                    const data = (totalLoaded - dataBuffer[0]) / 125000,
                          time = now - timeBuffer[0],
                          speed = data / (time / 1000),
                          avgSpeed = ((this.totalSize + totalLoaded) / 125000) / ((this.totalTime + (now - avgStartTime)) / 1000);

                    // set current speed and max speed
                    this.setSpeed(speed, avgSpeed);
                    this.options.debug && console.log('Upload progress speed', speed, 'Mbit/s', 'time', (time / 1000), 's');

                    // send update callback
                    if (this.options.onUpdate !== null) {
                        this.options.onUpdate({
                            speed: this.speed,
                            maxSpeed: this.maxSpeed,
                            avgSpeed: this.avgSpeed
                        });
                    }

                    // remove all time from buffer where
                    // diff bigger than 'averageInterval'
                    do {
                        this.options.debug && console.log('Remove from buffer', timeBuffer[timeBuffer.length - 1] - timeBuffer[0]);
                        timeBuffer.shift();
                        dataBuffer.shift();
                    } while (timeBuffer[timeBuffer.length - 1] - timeBuffer[0] > this.options.averageInterval);

                    if (this.isProbe()) {
                        source.cancel('probe');
                        this.stop();
                    }
                }
            }.bind(this)
        }).then(function (response) {
            const fetchTime = Date.now() - avgStartTime,
                  fetchSpeed = (totalLoaded / 125000) / (fetchTime / 1000);
            // totalTime and totalSize are
            // used for calculation of avgSpeed
            this.totalTime += fetchTime; // add to previous _fetch time
            this.totalSize += totalLoaded; // add to previous _fetch size

            this.options.debug && console.log('Upload complete', fetchSpeed, 'Mbit/s', fetchTime / 1000, totalLoaded);

            if (this.isProbe()) {
                this.stop();
            }
        }.bind(this)).catch(function (error) {
            if (axios.isCancel(error)) {
                this.options.debug && console.log('Upload canceled', error.message);
            } else {
                this.options.debug && console.log('error', error);
            }
        }.bind(this));
    }

    // async test(size = null) {
    //
    //     if (size === null) {
    //         size = this.options.dataSize;
    //     }
    //
    //     let lastTime = Date.now(),
    //         lastLoaded = 0,
    //         progressCount = 0;
    //
    //     const payload = new Blob([this.generateRandomString(size)]);
    //     const source = axios.CancelToken.source();
    //
    //     await axios.post(this.options.apiUrl, payload, {
    //         cancelToken: source.token,
    //         onUploadProgress: function (e) {
    //             this.options.debug && console.log('Test upload progress start', e.loaded);
    //
    //             if (progressCount > 0) {
    //                 const speed = ((e.loaded - lastLoaded) / 125000) / ((Date.now() - lastTime) / 1000);
    //                 this.options.debug && console.log('Test upload progress speed', speed, 'Mbit/s');
    //                 source.cancel('Cutoff time');
    //
    //                 if (this.options.onComplete !== null) {
    //                     this.options.onComplete({
    //                         speed: speed
    //                     });
    //                 }
    //             }
    //
    //             lastTime = Date.now();
    //             lastLoaded = e.loaded;
    //
    //             progressCount += 1;
    //         }.bind(this)
    //     }).then(function (response) {
    //         progressCount -= 1;
    //         this.options.debug && console.log('Test upload complete');
    //     }.bind(this)).catch(function (error) {
    //         if (axios.isCancel(error)) {
    //             this.options.debug && console.log(error.message);
    //         } else {
    //             this.options.debug && console.log('error', error);
    //         }
    //     }.bind(this));
    //
    //     console.log('---');
    //
    //     if (progressCount < 3) {
    //         setTimeout(function() {
    //             this.test(size + size);
    //         }.bind(this), 2000);
    //     } else {
    //
    //     }
    // }

    generateRandomString(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
            charactersLength = characters.length;

        let result = '';

        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    }
}
