import React from 'react';
import PropTypes from 'prop-types';

const Button = (props) => {
    return (
        <g onClick={() => {
            props.disabled || props.onClick();
        }}
           transform={`translate(${props.x} ${props.y})`}
           style={{cursor: props.disabled ? 'not-allowed' : 'pointer'}}
        >
            <rect x="0" y="0" width="130" height="30" rx="5" fillx="url(#outer-active)" fill={ props.disabled ? 'url(#outer-disabled)' : 'url(#outer-active)' }/>
            <rect x="1" y="1" width="128" height="28" rx="4" fillx="url(#inner-active)" fill={ props.disabled ? 'url(#inner-disabled)' : 'url(#inner-active)' }/>
            <text fill="#000000" fontWeight="bold" x="65" y="14" fontSize="12" dominantBaseline="middle" textAnchor="middle" opacity=".3">{props.title}</text>
            <text fill="#ffffff" fontWeight="bold" x="65" y="15" fontSize="12" dominantBaseline="middle" textAnchor="middle">{props.title}</text>
        </g>
    )
};

Button.propTypes = {
    title: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
};

Button.defaultProps = {
    x: 0,
    y: 0
};

export default Button;
