import React from 'react';
import * as GaugeTypes from '../../constants/gauge';

export default function GaugeTime({label, unit, direction, value, range, pointerCalculation}) {

    const handAngle = pointerCalculation(
            direction !== GaugeTypes.CCW ? 135 : 225,
            value,
            range,
            direction
        );

    return (
        <>
            <defs>
                <linearGradient id="gauge-time-b" x1="63.7" x2="-6.7" y1="-34.2" y2="72.9" gradientUnits="userSpaceOnUse" spreadMethod="pad">
                    <stop offset="0%" stopColor="#96949A"/>
                    <stop offset="23.9%" stopColor="#FEFEFE"/>
                    <stop offset="78.4%" stopColor="#FEFEFE"/>
                    <stop offset="100%" stopColor="#9D9CA1"/>
                </linearGradient>
                <linearGradient id="gauge-time-e" x1="-55.2" x2="-9.8" y1="-39.4" y2="-5.4" gradientUnits="userSpaceOnUse" spreadMethod="pad">
                    <stop offset="19.6%" stopColor="#767D84"/>
                    <stop offset="41.6%" stopColor="#FAFAFA"/>
                    <stop offset="55.3%" stopColor="#DCDDDF"/>
                </linearGradient>
                <linearGradient id="gauge-time-f" x1="9.2" x2="-47.6" y1="-2.3" y2="-59.3" gradientUnits="userSpaceOnUse" spreadMethod="pad">
                    <stop offset="0%" stopColor="#3E2626"/>
                    <stop offset="53.3%" stopColor="#4F3A38"/>
                    <stop offset="100%" stopColor="#8C827C"/>
                </linearGradient>
                <linearGradient id="gauge-time-g" x1="0" x2="0" y1="3.7" y2="-382.1" gradientUnits="userSpaceOnUse" spreadMethod="pad">
                    <stop offset="31.4%" stopColor="#FFF" stopOpacity="0"/>
                    <stop offset="100%" stopColor="#FFF" stopOpacity=".8"/>
                </linearGradient>
                <linearGradient id="gauge-time-h" x1="-229.4" x2="-96" y1="31.6" y2="196.6" gradientUnits="userSpaceOnUse" spreadMethod="pad">
                    <stop offset="31.4%" stopColor="#FFF" stopOpacity="0"/>
                    <stop offset="100%" stopColor="#FFF" stopOpacity=".4"/>
                </linearGradient>
                <linearGradient id="gauge-time-i" x1="0" x2="0" y1="-.2" y2="385.6" gradientUnits="userSpaceOnUse" spreadMethod="pad">
                    <stop offset="72.2%" stopColor="#FFF" stopOpacity="0"/>
                    <stop offset="79.2%" stopColor="#FFF" stopOpacity=".4"/>
                    <stop offset="100%" stopColor="#FFF" stopOpacity=".2"/>
                </linearGradient>
                <radialGradient id="gauge-time-a" cx="0" cy="0" r="76.6" fx="0" fy="0" gradientTransform="translate(-.7 -.2)" gradientUnits="userSpaceOnUse" spreadMethod="pad">
                    <stop offset="89.4%" stopColor="#887B76"/>
                    <stop offset="91.8%" stopColor="#0C0201"/>
                    <stop offset="92.9%" stopColor="#0C0201"/>
                    <stop offset="95.7%" stopColor="#958884"/>
                </radialGradient>
                <radialGradient id="gauge-time-c" cx="0" cy="0" r="4.2" fx=".1" fy="0" gradientTransform="matrix(1 0 -.00298 3.85714 4.7 .1)" gradientUnits="userSpaceOnUse" spreadMethod="pad">
                    <stop offset="36.9%" stopColor="#6D3D00"/>
                    <stop offset="62%" stopColor="#ECE7E2"/>
                </radialGradient>
                <radialGradient id="gauge-time-d" cx="0" cy="0" r="11.6" fx="4.6" fy="-4.6" gradientTransform="matrix(.70818 -.70603 .5674 .56955 -4.2 -2.7)" gradientUnits="userSpaceOnUse" spreadMethod="pad">
                    <stop offset="46.3%" stopColor="#FFF"/>
                    <stop offset="76.1%" stopColor="#6D3D00"/>
                </radialGradient>
                <g id="gauge-time-j">
                    <path fill="#002A3A" d="M37.1-64.7Q20.8-74.3.4-74.3q-30.7 0-52.1 21.8Q-73.5-31-73.5 0q0 3.5.3 7 2.3 26.3 21.4 45.5Q-30.5 74.3.3 74.3q30.8 0 52.4-21.9Q74.2 30.8 74.2 0q0-30.8-21.6-52.5-7.2-7.4-15.5-12.2M51.6-51q21 21 20.9 50.9 0 29.8-21.2 51Q30.1 72.1.2 72.1q-29.8.2-51-21-21-21-21-51 .1-29.8 21.3-51 21.3-21.3 51-21.4 30 0 51.1 21.1z"/>
                    <path fill="url(#gauge-time-a)" d="M72.5-.3q.2-29.8-20.9-50.9-21.1-21.1-51-21-29.8 0-51 21.3-21.3 21.2-21.4 51 0 30 21 51 21.1 21.1 51 21 29.9 0 51.1-21.3 21.2-21.1 21.2-51M7-69.8q13.6 1.3 26 8.4 13.2 7.6 24 21.1Q46.7-52 34-58.9q-3.9-2-7.9-3.6 11.8 5 21.2 15 10.4 11 15.1 24 2.5 6 3.8 12.3 2.3 12-.2 24.3-2.2 11.9-8.6 22.4-6.4 10.6-15.9 18.2-9.9 7.8-22.2 11.3Q10 68-.9 68q-28.3.3-48-19.5-6-6-10.2-12.9 1.8 3.8 4 7.6-9.7-14.2-13-29.2-3-13.8-.3-27.4 2.5-12.8 9.8-24 7-10.8 17.4-18.7 10.4-7.8 22.7-11.4Q-6-71.2 6.8-69.8M53.2-53v.1q-7.5-7.1-16-11.8 8.3 4.9 15.5 12.2Q74.3-30.8 74.3 0T52.6 52.4Q31 74.3.4 74.3q-30.7 0-52.1-21.8Q-71 33.2-73.3 7q1.8 26.7 20.5 46.3l.3.3.2.1.1.1q.4.3.6.7Q-30 75 0 75q31.1 0 53-22Q75 31 75 0T53.1-53z"/>
                    <path fill="#EEF2F7" d="M-63.9 19.8q-3.3-8.9-3.6-18.1 0 5.4 1 10.9 1.9 11.5 7.4 23 4.2 6.9 10.3 13Q-29.3 68.2-1 68q10.9 0 20.4-3-12.2 3.5-26.8 1.7-14.6-1.8-28-10.5 15.3 8.1 29.2 9h1.6Q-29 63.7-46.6 46q-3.1-3.1-5.8-6.5l-1.4-1.9q-6.6-8.6-10-17.9m11-61.3l.7-.9q-2 2.3-3.6 4.6-5.7 8.1-8.7 17.2l.9-2.3q3.8-9.8 10.8-18.6M3.5-66.3q6.9.6 13.5 2.6 16.4 4.3 29.1 17.2 1 .9 1.8 1.9 4.8 4.5 8.4 9.9 3.7 5.3 6.3 11.3-4.8-13.1-15.2-24Q38-57.6 26.2-62.6l-2.6-1Q16.5-65.9 9-66.7-3.5-68-15.8-64.9q-6.2 1.6-12 4.2 5-2.1 10.2-3.5 10.5-3 21.2-2z"/>
                    <path fill="url(#gauge-time-b)" d="M56.3-34.8q-3.6-5.3-8.4-9.9-4.5-4.4-10-8.3 9.6 7.5 16 17.5 6.6 9.9 8.9 21.7Q65.2-2 63.2 10q-2 11.8-7.7 22.2-5.8 10.6-14.9 18.3Q31.5 58 19.7 62q-9.8 3.3-21 3.3l-3.3-.1h-1.6q-13.9-.9-29.2-9Q-22 65-7.4 66.8 7.2 68.5 19.5 65q12.3-3.5 22.1-11.3 9.5-7.6 15.9-18.2 6.4-10.5 8.6-22.4 2.5-12.3.2-24.4-1.3-6.3-3.8-12.1-2.5-6-6.3-11.4z"/>
                    <path fill="#FFF" d="M23.6-63.5l2.6 1q4 1.5 7.9 3.6Q46.7-52 57-40.3q-10.8-13.5-24-21-12.4-7.2-26-8.5-12.9-1.4-25.6 2.3-12.2 3.6-22.6 11.4-10.4 7.9-17.4 18.7-7.3 11.1-9.8 24Q-71 .2-68 14.1q3.2 15 12.9 29.1-2.2-3.9-4-7.6-5.5-11.5-7.4-23-1-5.5-1-10.9l-.1-1.6q0-7.5 1.6-15l.5-1.5 1.1-4.2q3-9.1 8.7-17.1 1.7-2.4 3.6-4.6l.2-.3q5.9-7 13.6-12.3 5-3.4 10.4-5.9 5.8-2.6 12-4.2 12.4-3.1 25-1.7 7.4.8 14.5 3.3z"/>
                    <path fill="#DDD" d="M25.7-60.2q-11.3-4.8-23-5.3-10.9-.5-21.3 2.6-9.9 3-18.5 9.2-8.5 6-14.8 14.4-6.3 8.2-9.9 18-3.5 10-3.8 20.4-.2 10.9 3.3 21.4 3.8 11.1 11.5 21l-1.6-1.9q2.6 3.4 5.8 6.5 17.7 17.6 42 19l3.2.2q11.3 0 21.1-3.3 11.8-4 20.9-11.5 9.1-7.6 15-18.3 5.6-10.5 7.6-22.2 2-12-.4-23.8-2.3-11.8-8.9-21.7-6.4-10-16-17.5 5.5 3.9 10 8.4l-1.8-2Q33.3-59.3 17-63.5q4.4 1.3 8.7 3.4M0-7.9q3 0 5.1 1.9l.5.3v.2Q7.9-3.3 7.9 0q0 3.3-2.3 5.5l-.4.5h-.1Q2.9 8 0 8q-3.3 0-5.5-2.4Q-8 3.3-8 0q0-3.3 2.4-5.5Q-3.3-8 0-8z"/>
                    <path fill="url(#gauge-time-c)" d="M5.6-5.5v-.2L5-6V6h.1l.4-.5Q7.9 3.3 7.9 0q0-3.3-2.3-5.5z"/>
                    <path fill="url(#gauge-time-d)" d="M5.1-6Q3-8 0-8q-3.3 0-5.5 2.4Q-8-3.3-8 0q0 3.3 2.4 5.5Q-3.3 8 0 8q2.9 0 5.1-1.9V-6z"/>
                    <path fill="url(#gauge-time-e)" d="M17-63.6q-6.6-2.1-13.5-2.6-10.7-1-21.2 2-5.3 1.4-10.2 3.5-5.4 2.5-10.4 5.9-7.8 5.3-13.6 12.2l-.2.3-.6.8q-7 8.8-10.8 18.6l-.9 2.3-1.1 4.2-.5 1.5q-1.6 7.5-1.6 15v1.6q.4 9.2 3.7 18.1 3.5 9.3 10.1 17.9l1.4 1.9 1.6 1.9q-7.7-9.9-11.5-21Q-65.8 10-65.5-.8q.3-10.4 3.8-20.5 3.6-9.7 9.9-18 6.3-8.3 14.8-14.3 8.6-6.2 18.5-9.1 10.4-3.3 21.3-2.7 11.6.5 23 5.3-4.3-2.1-8.7-3.4z"/>
                    <path fill="url(#gauge-time-f)" d="M53-53l-.2-.3Q30.9-75 0-75q-31 0-53 22T-75 0q0 31 22 53l.3.3Q-71.5 33.7-73.3 7q-.3-3.5-.3-7 0-30.9 21.6-52.5Q-30.4-74.3.4-74.3q20.4 0 36.8 9.6Q45.5-60 53-52.9v-.1z"/>
                </g>

            </defs>
            <use xlinkHref="#gauge-time-j"/>
            <text x="0" y="20" fontSize="12" dominantBaseline="middle" textAnchor="middle">{label}</text>
            <text x="0" y="31" fontSize="9" dominantBaseline="middle" textAnchor="middle">{unit}</text>

            <g transform={direction !== GaugeTypes.CCW ? "scale(-1 1)": ""}>
                <path fill="#D00" d="M30.6-53.1L28.8-50q6.3 3.6 11.8 9.3l2.6-2.7q-5.8-6-12.6-9.9m-15.8-2.5h.1q7.3 2 13.9 5.8l1.8-3.3q-6.9-4.1-14.8-6.1l-1 3.6M0-61.2v3.7q7.8 0 14.8 1.8l1-3.6q-7.5-2-15.8-2z"/>
                <path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".1" d="M14.8-55.7l1-3.6.4-1.3m-1.6 6.1l.3-1.2h0m16.4 1.5l-.7 1-1.8 3.3m25.4 18.5l-5.4 3.2m11.7 12l-6.1 1.6M28.2-48.9l.6-1m25.6 64.5l6 1.7m-6.2 15l-5.4-3.1M14.6 54.5l1.6 6m15.1-6.2l-3.2-5.4m-42.7 5.6l-1.6 6m-15.1-6.2l3.2-5.4"/>
                <path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M0-53.1V-62.5m40.6 21.9l2.6-2.7 1-.9m-3.6 3.6l-3.1 3M62.5 0H53M0 53.1v9.4m37.5-24.9l6.6 6.6m-81.6-6.6l-6.6 6.6"/>
            </g>

            {range.map((value, index) => {
                let rotation = direction === GaugeTypes.CCW ? 225 + (index * -45) : 135 + (index * 45);
                return <text key={index} transform={`rotate(${rotation.toString()} 0 0) translate(0 -45)`} x="0" y="0" fontSize="12" dominantBaseline="middle" textAnchor="middle">{value}</text>
            })}

            <g transform={`rotate(${handAngle.toString()})`}>
                <path fill="#900" d="M.8-54.6L.5-55l-.3.3-.2.3.1.4L2-14 .9 3q0 .9-.6 1.5l-.3.3-.5.2h1l.9-.5q.5-.6.5-1.5L3-14 1.1-53.9q0-.4-.3-.8z"/>
                <path fill="#D00" d="M0-54.4v.1l-.1.4L-2-14-.9 3q0 .9.6 1.5l.3.3.3-.3Q1 3.9 1 3L2-14 .1-53.9v-.5z"/>
                <path fill="#F33" d="M0-54.4l.2-.3.3-.1L0-55l-.5.1-.3.3q-.3.3-.3.8L-3-14l1.1 17q0 .9.5 1.5l.9.5.5-.3-.3-.3Q-1 3.9-1 3L-2-14l1.9-39.9.1-.4v-.1z"/>
            </g>

            <g transform="scale(0.17)">
                <path fill="url(#gauge-time-g)" d="M288.8-35.4q27.1-12.9 27.1-28.6 0-130.9-92.4-223.4Q130.8-380 0-380t-223.4 92.6Q-316-195-316-64q0 15.8 27.1 28.6 25.2 12 71.6 20.5Q-128.4 1.5 0 1.5q128.3 0 217.2-16.4 46.4-8.5 71.6-20.5z"/>
                <path fill="url(#gauge-time-h)" d="M125.8-99.6q-18.9-20.3-65.5-27.8-42.4-6.8-102.8-2.3-56.4 4.2-118.3 17.4Q-221-99.3-272.4-81q-52.8 18.9-83 39Q-388-20.1-388-.8q.1 160.8 113.8 274.5 13.7 13.7 48.5 1.3 32.2-11.5 80-44.4 42.2-29.1 95.8-75.9 42.4-36.9 107-98.6 58.9-56 75-96.4 14.7-36.7-6.3-59.2z"/>
                <path fill="url(#gauge-time-i)" d="M316 67.5q0-15.8-27.2-28.6-25.2-12-71.6-20.5Q128.4 2.1 0 2.1q-128.3 0-217.2 16.3-46.4 8.6-71.6 20.5-27.1 12.9-27.1 28.6 0 130.9 92.4 223.4 92.6 92.6 223.5 92.6t223.4-92.6Q316 198.4 316 67.5z"/>
            </g>
        </>
    );
}
