export default class Timer {

    constructor(options) {
        this.options = {
            refresh: 20, // per second
            duration: 10,
            onUpdate: null,
            onComplete: null,
            debug: false,
            ...options
        };

        this.interval = null;
        this.timestamp = null;
    }

    stop() {

        if (this.interval !== null) {
            this.options.debug && console.log('Timer stop');
            clearInterval(this.interval);

            let time = this.getTime();

            this.options.onComplete && this.options.onComplete(time);
            this.interval = null;
            this.timestamp = null;
        } else {
            this.options.debug && console.log('Timer stop null');
        }
    }

    start() {
        this.options.debug && console.log('Timer start');

        this.timestamp = Date.now();

        this.interval = setInterval(function onTimerInterval() {
            let time = this.getTime();

            if (time > this.options.duration) {
                this.stop();
            } else {
                this.options.onUpdate && this.options.onUpdate(time);
            }
        }.bind(this), Math.ceil(1000 / this.options.refresh));
    }

    getTime() {
        return (Date.now() - this.timestamp) / 1000;
    }
}
