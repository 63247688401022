import CryptoJS from 'crypto-js';
import axios from 'axios';

const KEY = '9dq5Aq46',
      // URL = 'https://speedmeter.galik.it/log.php';
      URL = 'https://www.icss.sk/speedmeterlog.php';

const keyHex = CryptoJS.enc.Utf8.parse(KEY);

export default class Log {

    constructor(options) {
        this.options = {
            debug: false,
            ...options
        };
    }

    post(data) {
        const encrypted = CryptoJS.DES.encrypt(data.join('|'), keyHex, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });

        const bodyFormData = new FormData();
            bodyFormData.append('p', encrypted.toString());

        axios({
            method: 'post',
            url: URL,
            data: bodyFormData
        })
        .then(function (response) {
            this.options.debug && console.log(response);
        }.bind(this)).catch(function (error) {
            this.options.debug && console.log(error);
        }.bind(this));
    }
}
