import axios from 'axios';

export default class Download {

    constructor(options) {
        this.options = {
            averageInterval: 750,
            duration: 10,
            apiUrl: 'https://speedmeter.galik.it/assets/data.php',
            // apiUrl: 'http://localhost:5000/download.stream?speed=100',
            // apiUrl: 'http://test.icss.sk/30min1.mp3',
            onUpdate: null,
            onComplete: null,
            ...options
        };

        this.timeout = null;
    }

    inProgress() {
        return this.timeout !== null;
    }

    isProbe() {
        return this.options.duration === 0;
    }

    setSpeed(speed, avgSpeed) {
        this.speed = speed;
        this.avgSpeed = avgSpeed;
        if (this.speed > this.maxSpeed) {
            this.maxSpeed = speed;
        }
    }

    start() {
        this.options.debug && console.log('Download start');
        this.speed = 0;
        this.avgSpeed = 0;
        this.maxSpeed = 0;
        this.totalSize = 0; // total data loaded in previous fetches
        this.totalTime = 0; // total time loaded in previous fetches

        if (this.inProgress()) {
            this.stop();
        }

        if (this.isProbe() === false) {
            this.timeout = setTimeout(function onDownloadTimeout() {
                this.options.debug && console.log('Download timeout');
                this.stop(false);
            }.bind(this), this.options.duration * 1000);

            // no proble
            this._loop();
        } else {
            this._fetch();
        }

    }

    stop(callback = true) {
        if (this.inProgress() || this.isProbe()) {
            this.options.debug && console.log('Download stop');
            clearTimeout(this.timeout);
            this.timeout = null;

            if (this.options.onComplete !== null && callback === true) {
                this.options.onComplete({
                    speed: this.speed,
                    avgSpeed: this.avgSpeed,
                    maxSpeed: this.maxSpeed
                });
            }
        } else {
            this.options.debug && console.log('Download stop null');
        }
    }

    async _loop() {
        this.options.debug && console.log('Download _loop');

        do {
            await this._fetch();
        } while (this.inProgress());
    }

    async _fetch() {
        this.options.debug && console.log('Download _fetch');

        const timeBuffer = [],
              dataBuffer = [],
              avgStartTime = Date.now();

        // total bytes loaded in current _fetch
        let totalLoaded = 0;

        // at the beginning there is no data
        // timeBuffer.push(avgStartTime);
        // dataBuffer.push(0);
        dataBuffer.push(0);

        const source = axios.CancelToken.source();

        await axios.get(this.options.apiUrl, {
            cancelToken: source.token,
            onDownloadProgress: function (e) {
                if (this.inProgress() === false && this.isProbe() === false) {
                    source.cancel('progress');
                    return;
                }

                this.options.debug && console.log('Download progress start', e.loaded, e.timeStamp);

                const now = Date.now();

                timeBuffer.push(now);
                dataBuffer.push(e.loaded);

                // total bytes loaded in current _fetch
                // used for calculation of avgSpeed
                totalLoaded = e.loaded;

                if (timeBuffer[timeBuffer.length - 1] - timeBuffer[0] > this.options.averageInterval) {
                    const data = (totalLoaded - dataBuffer[0]) / 125000,
                          time = now - timeBuffer[0],
                          speed = data / (time / 1000),
                          avgSpeed = ((this.totalSize + totalLoaded) / 125000) / ((this.totalTime + (now - avgStartTime)) / 1000);

                    // set current speed and max speed
                    this.setSpeed(speed, avgSpeed);
                    this.options.debug && console.log('Download progress speed', speed, 'Mbit/s', 'time', (time / 1000), 's');

                    // send update callback
                    if (this.options.onUpdate !== null) {
                        this.options.onUpdate({
                            speed: this.speed,
                            maxSpeed: this.maxSpeed,
                            avgSpeed: this.avgSpeed
                        });
                    }

                    // remove all time from buffer where
                    // diff bigger than 'averageInterval'
                    do {
                        this.options.debug && console.log('Remove from buffer', timeBuffer[timeBuffer.length - 1] - timeBuffer[0]);
                        timeBuffer.shift();
                        dataBuffer.shift();
                    } while (timeBuffer[timeBuffer.length - 1] - timeBuffer[0] > this.options.averageInterval);

                    if (this.isProbe()) {
                        source.cancel('probe');
                        this.stop();
                    }
                }
            }.bind(this)
        }).then(function (response) {
            const fetchTime = Date.now() - avgStartTime,
                  fetchSpeed = (totalLoaded / 125000) / (fetchTime / 1000);
            // totalTime and totalSize are
            // used for calculation of avgSpeed
            this.totalTime += fetchTime; // add to previous _fetch time
            this.totalSize += totalLoaded; // add to previous _fetch size

            this.options.debug && console.log('Download complete', fetchSpeed, 'Mbit/s', fetchTime / 1000, totalLoaded);

            if (this.isProbe()) {
                this.stop();
            }
        }.bind(this)).catch(function (error) {
            if (axios.isCancel(error)) {
                this.options.debug && console.log('Download canceled', error.message);
            } else {
                this.options.debug && console.log('error', error);
            }
        }.bind(this));
    }
}
