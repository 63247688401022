import React from 'react';
import PropTypes from 'prop-types';
import Probe from './Probe';
import DownloadGauges from './DownloadGauges';
import UploadGauges from './UploadGauges';
import Button from './Button';
import Online from './Online';
import Log from './../utils/Log';

const MEASURE_BOTH = 'MEASURE_BOTH',
    MEASURE_UPLOAD = 'MEASURE_UPLOAD',
    MEASURE_DOWNLOAD = 'MEASURE_DOWNLOAD';

class App extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            active: false,
            ready: false,
            online: navigator.onLine,
            download: null,
            upload: null,
            debug: false
        };

        this.downloadGauges = React.createRef();
        this.uploadGauges = React.createRef();
        this.measureType = null;

        this.width = 580;
        this.height = 430;

        this.clearData();
        this.log = new Log({ debug: true });

        window.addEventListener('online', this.onUpdateOnlineStatus.bind(this));
        window.addEventListener('offline', this.onUpdateOnlineStatus.bind(this));
    }

    clearData() {
        this.speed = {
            download: {
                avg: 0,
                max: 0
            },
            upload: {
                avg: 0,
                max: 0
            },
        };
    }

    onUpdateOnlineStatus(e) {
        this.setState({
            online: navigator.onLine
        });

        this.state.debug && console.log('online', this.state.online);
    }

    onReady(payload) {
        this.state.debug && console.log('onReady', payload);

        this.setState({
            ready: true,
            download: payload.download,
            upload: payload.upload
        });
    }

    onBandwidthClick() {
        this.measureType = MEASURE_BOTH;
        this.downloadGauges.current.start();

        this.setState({
            active: true
        });
    }

    onDownloadClick() {
        this.measureType = MEASURE_DOWNLOAD;
        this.downloadGauges.current.start();

        this.setState({
            active: true
        });
    }

    onUploadClick() {
        this.measureType = MEASURE_UPLOAD;
        this.uploadGauges.current.start();

        this.setState({
            active: true
        });
    }

    onDownloadComplete(payload) {
        this.state.debug && console.log('onUploadComplete');

        this.speed.download.avg = payload.avgSpeed;
        this.speed.download.max = payload.maxSpeed;

        if (this.measureType === MEASURE_BOTH) {
            this.uploadGauges.current.start();
        } else {
            this.setState({
                active: false,
            });

            this.postLog();
        }
    }

    onUploadComplete(payload) {
        this.state.debug && console.log('onUploadComplete');

        this.speed.upload.avg = payload.avgSpeed;
        this.speed.upload.max = payload.maxSpeed;

        this.setState({
            active: false
        });
        this.postLog();
    }

    onStopClick() {
        this.state.debug && console.log('onStopClick');

        this.setState({
            active: false
        });

        this.downloadGauges.current.stop();
        this.uploadGauges.current.stop();
    }

    postLog() {
        this.log.post([
            this.speed.download.avg.toFixed(1),
            this.speed.download.max.toFixed(1),
            this.speed.upload.avg.toFixed(1),
            this.speed.upload.max.toFixed(1),
            navigator.platform,
            navigator.userAgent,
            `Speedmeter version ${this.props.version}`
        ]);

        this.clearData();
    }

    render() {
        return (
            <svg
                style={{
                    display: 'block',
                    maxWidth: '100vw',
                    maxHeight: '100vh',
                    userSelect: 'none'
                }}
                xmlns="http://www.w3.org/2000/svg"
                xlinkHref="http://www.w3.org/1999/xlink"
                viewBox={`0 0 ${this.width} ${this.height}`}>

                {this.state.ready &&
                <>
                    <DownloadGauges ref={this.downloadGauges} duration={10}
                                    limit={this.state.download} minRange={50}
                                    complete={this.onDownloadComplete.bind(this)}/>
                    <UploadGauges ref={this.uploadGauges} duration={10}
                                  limit={this.state.upload} minRange={20}
                                  complete={this.onUploadComplete.bind(this)}/>
                    <g transform="translate(0 400)">
                        <defs>
                            <linearGradient id="inner-active" x1="9.9" x2="9.9" y1="14.1" y2="-15.8" gradientUnits="userSpaceOnUse" spreadMethod="pad">
                                <stop offset="0%" stopColor="#bc89bc"/>
                                <stop offset="100%" stopColor="#d0acd0"/>
                            </linearGradient>
                            <linearGradient id="outer-active" x1="-10.1" x2="-10.1" y1="14.1" y2="-15.8" gradientUnits="userSpaceOnUse" spreadMethod="pad">
                                <stop offset="0%" stopColor="#966d96"/>
                                <stop offset="72.5%" stopColor="#c1a1c1"/>
                                <stop offset="100%" stopColor="#d0acd0"/>
                            </linearGradient>
                            <linearGradient id="inner-disabled" x1="9.9" x2="9.9" y1="14.1" y2="-15.8" gradientUnits="userSpaceOnUse" spreadMethod="pad">
                                <stop offset="0%" stopColor="#9e9e9e"/>
                                <stop offset="100%" stopColor="#bbbbbb"/>
                            </linearGradient>
                            <linearGradient id="outer-disabled" x1="-10.1" x2="-10.1" y1="14.1" y2="-15.8" gradientUnits="userSpaceOnUse" spreadMethod="pad">
                                <stop offset="0%" stopColor="#7e7e7e"/>
                                <stop offset="72.5%" stopColor="#aeaeae"/>
                                <stop offset="100%" stopColor="#bbbbbb"/>
                            </linearGradient>
                        </defs>
                        <Button onClick={this.onBandwidthClick.bind(this)} title="Spustiť meranie" disabled={this.state.active}/>
                        <Button x="140" onClick={this.onDownloadClick.bind(this)} title="Zmerať download" disabled={this.state.active}/>
                        <Button x="280" onClick={this.onUploadClick.bind(this)} title="Zmerať upload" disabled={this.state.active}/>
                        <Button x="450" onClick={this.onStopClick.bind(this)} title="Zastaviť meranie" disabled={this.state.active === false}/>
                    </g>
                </>
                }

                {!this.state.ready &&
                <Probe width={this.width} height={this.height} duration={2} complete={this.onReady.bind(this)}/>}

                {!this.state.online && <Online width={this.width} height={this.height}/>}

                <g transform="translate(580 0)" fill="#ffffff">
                    <text x="0" y="10" fontSize="9" dominantBaseline="middle" textAnchor="end">
                        © ICS Systems s.r.o.
                    </text>
                    <text x="0" y="24" fontSize="9" dominantBaseline="middle" textAnchor="end">
                        Version {this.props.version}
                    </text>
                </g>
            </svg>
        );
    }
}

App.propTypes = {
    upload: PropTypes.number.isRequired,
    download: PropTypes.number.isRequired,
    version: PropTypes.string.isRequired
};

App.defaultProps = {
    upload: 20,
    download: 50
};

export default App;
